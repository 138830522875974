@use 'src/styles/variables' as var;

.comment-item {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 12px 8px;

  > .is-removed-message {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    padding: 8px;
    border-radius: 3px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: var.$grey;
    background-color: rgba(255, 255, 255, 0.65);
    backdrop-filter: blur(6px);
  }

  > .header {
    display: flex;
    flex-direction: row;
    font-size: 11px;
    gap: 8px;
    align-items: center;

    &.reply {
      justify-content: end;

      > .details {
        order: 1;
        align-items: end;
      }

      > .avatar {
        order: 2;
      }

      .reply-bar {
        order: 3;
        margin-top: -10px;
        height: calc(100% + 10px);
        width: 5px;
        border-radius: 3px;
      }
    }

    > .details {
      display: flex;
      flex-direction: column;

      .name {
        font-weight: bold;
      }
    }
  }

  > .content {
    padding: 8px 12px;
    border-radius: 3px;
    word-break: break-word;
    white-space: pre-line;
  }

  > .reply-textbox {
    margin: 4px 0;
    padding: 8px 8px 4px 8px;
    border-radius: 3px;
    background-color: var.$white;
  }

  > .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .reply-button,
    > .more-button {
      padding: 0;
    }
  }
}
