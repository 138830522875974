@use 'src/styles/variables' as var;

.coordinator-avatar {
  margin: 1em auto !important;
  width: 3.5em;
  display: flex;
  flex-direction: column;
  align-items: center;

  span.avatar-name {
    background-color: var.$blue;
    border-radius: 5px;
    padding: 0px 3px;
  }
}
