@use 'src/styles/variables' as var;
@use 'src/styles/mixin' as mixin;

.permit-notes {
  width: 100%;
  height: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
  @include mixin.radius-scrollbar(10px);
  &.editable {
    cursor: pointer;
  }

  .MuiSvgIcon-root {
    color: var.$blue;
    margin-right: 10px;
  }
  > header {
    .MuiTypography-root {
      color: var.$submitted-permit-header-color;
    }
  }

  .permit-notes-tile {
    background-color: var.$white;
    overflow: visible;

    > .title {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px;
      border-radius: 5px;

      &:hover {
        background: #f7f9fc;
        cursor: pointer;
      }
    }
    .notes-list-view {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 30px;
      gap: 20px;
    }
  }
}

.notes-date-col {
  width: 6%;
  min-width: 60px;
}
.notes-quicknote-col {
  width: 14%;
  min-width: 140px;
}
.notes-modifyby-col {
  width: 8%;
  min-width: 100px;
}
.notes-customnote-col {
  width: 16%;
  min-width: 200px;
}

.needsaction-message-col {
  width: 70%;
  min-width: 200px;
}

.needsaction-resolve-col {
  width: 10%;
  min-width: 40px;
}

.needsaction-created-col {
  width: 20%;
  min-width: 40px;
}

.notes-dialog {
  .notes-list-dialog {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .first-col {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 20px;
  }

  .add-button {
    height: 32px;
    width: 32px;
    padding: 0;

    .MuiSvgIcon-root {
      height: 100%;
      width: 100%;
    }
  }

  .attachment-item {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > .info-grid {
      align-items: center;

      > .MuiGrid-item {
        overflow: hidden;
        text-overflow: ellipsis;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
