.login-container {
   background-color: #002957;
   display: flex;
   min-height: inherit;
   font-family: unset !important;
   min-width: 410px;

   .background-login {
      background-image: url(../../assets/images/backgrounds/login.png);
      background-size: cover;
      background-position: center center;
      min-height: inherit;
      width: 55%;
   }

   .login-page {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 45%;
   }

   .title {
      color: white;
      letter-spacing: 1px;
      font-size: 22px;
   }

   .slim-text {
      font-weight: 200;
      padding-right: 1em;
   }
   
   div.title, h2.login-label {
      text-align: left;
   }
   
   div.title {
      margin-top: 2em;
   }
   
   h2.login-label {
      margin-top: 2em;
   }

   h1, h2 {
      font-weight: 600;
      color: white;
   }
   
   h2 {
      font-weight: 400;
   }
   
   
   div.login-button-container {
      width: 100%;
      margin-left: 15.5em;
      margin-right: 0;

      button.login {
      margin-right: 0;
      width: 70px !important;
      }
   }

   .logo-container {      
      margin: auto;
      padding-left: 5em;
      margin-top: 7em; 
      margin-bottom: 7em;
   }

   input.MuiOutlinedInput-input {
      color: white;
      border: white;
      height: 10px;
      background-color: #002957;
   }

   .full-width {
      width: 100%;
   }

   legend.css-dhh0px, label.MuiInputLabel-root {
      display: none;
   }

   fieldset.MuiOutlinedInput-notchedOutline {
      z-index: 100;
      border-color: white !important;
   }

   .form-control:focus {
      background-color: rgb(0,41,87) !important;
      color: #fff;
   }

   .form-control {
      color: lightgray;
      padding: 1.20em 0.7em;
      background-color: rgb(0,41,87) !important;
      padding-top: 1em;
   }

   .mat-form-field-infix {
      padding: 0;
   }
   
   input.mat-input-element {
      caret-color: white;
   }
   
   button.MuiButton-root{
      text-transform: none !important;
      background-color: rgb(0,152,218) !important;
      color: #fff !important;
      width: 320px;
      height: 40px;
      font-weight: 200;
      
      z-index: 1;
      letter-spacing: 0.5px;
   }
   
   #sempra, #socal, #sdge {
      height: 80px;
      padding-left: 1em;
   }
  
   span.button-label {
      color: white;
      padding-bottom: 20px;
   }
  
   .MuiOutlinedInput-root {
      margin-top: 0.5em;
            
    fieldset.MuiOutlinedInput-notchedOutline:hover {
         border-color: white !important;
      }
   }
}

