@use 'src/styles/variables' as var;
@use 'src/styles/mixin' as mixin;

#completed {
  @include mixin.submittedPermitDetailBackground($isCompleted: true);
}

@mixin detailsBackgroundColor($isCompleted) {
  @if $isCompleted {
    background-color: #daebff;
  } @else {
    background-color: #e1f2e2;
  }
}

#completed {
  @include detailsBackgroundColor($isCompleted: false);
}

.permit-details {
  width: 100%;
  height: 100%;
  @include mixin.submittedPermitDetailBackground($isCompleted: false);
  border-radius: 10px;
  padding: 30px 30px 0px 30px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  @include mixin.radius-scrollbar(10px);
  > header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    > span {
      font-weight: 300;
      display: flex;
    }
    .MuiTypography-root {
      color: var.$submitted-permit-header-color;
    }
  }
  > .body {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    > .content {
      width: 100%;
      height: 100%;
      .completed {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 40px;
      }
      .cell {
        color: var.$submitted-permit-header-color;
        display: flex;
        flex-direction: column;
        :first-child {
          font-weight: 400;
        }
        :nth-child(2) {
          font-size: 16px;
          font-weight: 300;
        }
      }
    }
    .post {
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .MuiPaper-root {
        padding-left: 20px;
        :first-child {
          font-weight: 400;
        }
        :last-child {
          font-size: 16px;
          font-weight: 300;
        }
      }
    }
    .details-svg {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;

      &.mail-svg {
        padding-bottom: 24px;
      }
    }
  }
}

.pt-25{
  padding-top:25px;
}

.submitted-permit-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
  padding: 0;
  &-inputs {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 20px;
  }
  > .details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 75%;
    .forward-icon {
      position: relative;
      left: -5%;
      top: 10%;
    }
    .data {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      font-size: 16px;
      span:first-child {
        font-size: 10px;
        color: #a1a1a1;
      }
      span:last-child {
        font-weight: 300;
      }
    }
    > div {
      flex: 1 1;
    }
  }
  .agencies {
    display: flex;
    flex-direction: column;
    background-color: var.$white;
    padding: 16px;
    min-width: 344px;
    width: fit-content;
    border-radius: 5px;
    gap: 16px;
    .agency {
      display: flex;
      flex-direction: row;
      gap: 16px;
    }
    .add-button {
      height: 32px;
      width: 32px;
      padding: 0;
      .MuiSvgIcon-root {
        height: 100%;
        width: 100%;
      }
    }
  }
}

.break {
  flex-basis: 100%;
  height: 0;
  margin-bottom: 20px;
}

.align-icon-w-item{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@media (max-width: 900px) {
  .link-icon {
    display: none;
  }
}

.parentPermitSpan {
  cursor: pointer;
  text-decoration: underline;
}
