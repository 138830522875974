@use 'src/styles/variables' as var;
@use 'src/styles/mixin' as mixin;

.permit-schedule {
  width: 100%;
  max-height: 100%;
  border-radius: 10px;
  padding: 30px 30px 10px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //cursor: pointer;
  overflow: auto;
  @include mixin.radius-scrollbar(10px);

  > header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    .MuiTypography-root {
      color: var.$submitted-permit-header-color;
    }
    button {
      z-index: 1;
    }
  }
  > article {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .cell {
      display: flex;
      flex-direction: column;
      flex: 1 1;
      &:first-child {
        color: #f39b31;
        span:last-child {
          display: flex;
        }
      }
      span:first-child {
        color: var.$grey;
      }
      span:last-child {
        font-size: 16px;
      }
    }
  }
  > footer {
    span {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      font-size: 11px;
      mark {
        background-color: transparent;
        color: #008dd2;
      }
    }
  }
}

.schedule-dialog {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .description {
    font-size: 20px;
  }
  .dates-inline-container {
    display: inline-flex;

    .card-notification-container {
      display: block;

      .card-notification {
        background-color: bisque;
        min-height: 3em;
        margin: 0 1em 1em 1em;

        div {
          padding: 0.5em;
          height: auto;
        }
      }

      .MuiTypography-root {
        font-weight: 600;
      }
    }

    .cmp-due-date {
      margin-top: 2em;
      width: 100%;
      .MuiFormControl-root {
        width: 100%;
      }
    }
  }
  .times-container {
    display: inline-flex;
    align-items: center;
  }
}

.break {
  flex-basis: 100%;
  height: 0;
}

.my-25px{
  margin-top: 25px!important;
  margin-bottom:25px!important;
}
.mt-15px{
  margin-top:15px!important;
}
.mr-15px{
  margin-right:15px!important;
}