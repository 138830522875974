html,
body,
#root,
.App {
  min-height: 100vh;
  font-size: 15px;
}
body {
  overflow-y: auto;
}
.MuiAutocomplete-popper {
  min-width: fit-content;
}
