@use 'src/styles/variables' as var;

.permit-tcp {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding: 30px 30px 0px 30px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  > header {
    .MuiTypography-root {
      color: var.$submitted-permit-header-color;
    }
  }
  > article {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 100%;
    section:first-child {
      width: 50%;
    }
    section:last-child {
      width: 15%;
      justify-content: flex-end;
      padding: 0;
    }
    section {
      width: 35%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      height: 100%;
      padding-top: 30px;
      span:last-child {
        font-weight: 300;
      }
    }
  }
}
