.trench-specification {
  position: relative;
  display: flex;
  gap: 16px;
  padding: 24px;
  border-radius: 5px;
  background-color: white;

  .remove-button {
    position: absolute;
    top: 4px;
    right: 4px;
  }

  .first-col {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 20px;
  }

  .dimensions {
    display: flex;
    flex-direction: column;

    .title {
      font-weight: bold;
    }

    .inputs {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;
      height: 100%;

      .MuiOutlinedInput-root {
        width: 110px;
      }
    }
  }
}
