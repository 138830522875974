.tcp {
    .title {
        font-weight: normal;
        font-size: 20px;
        margin-bottom: 24px;
    }

    .options {
        margin-left: 16px;

        .no-checkbox {
            margin-left: 16px;
        }
    }
}
