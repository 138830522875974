.permit-form {
  min-height: 100vh;
  .permit-form-nav .MuiDrawer-paper {
    position: relative;
    height: 100vh;
  }

  .form-nav-header {
    .basic-info {
      width: 100%;
      position: relative;
      .info-block {
        margin: 0.5em 0;
        .title {
          font-size: 12px;
          opacity: 0.6;
        }
        .value {
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
        }
      }
      .arrow-button {
        top: 0;
        right: -15px;
        position: absolute;
      }
    }
  }

  .form-nav-header {
    background-color: #0c75df;
    text-align: left;
    min-height: auto;
    padding-bottom: 0;

    .delete-permit-button {
      display: flex;
      justify-content: center;
      min-width: 50px;
      margin: 0.5em 0.2em;
      padding: 6px 8px;
      color: #cd3729;
      background-color: #ffffff;
    }
  }

  div.form-steps {
    margin-top: 0.5em;
    div.form-nav-list {
      list-style: none;

      .submit-icon {
        color: white;
        font-size: 26px;
      }

      .MuiListItem-root {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.7em 1.5em;

        &:focus {
          background-color: rgb(0, 24, 52);
        }
        .item-name {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .checkmark {
          color: green;
          font-size: 22px;
        }
        .warning-bullet {
          color: rgb(255, 107, 0);
        }

        .warning-circle {
          color: green;
        }
      }

      .submit-button {
        min-width: 50px;
        margin: 0.5em 0.2em;
        padding: 6px 8px;

        &.Mui-disabled {
          opacity: 0.26;
          background-color: white;
          color: black;
        }
      }
    }
  }

  .assigned {
    flex-wrap: wrap;
    overflow: auto;
    display: flex;
    min-height: 7em;
    margin-right: 0.2em;
    padding-left: 0.5em;
  }
}

.empty-space {
  margin: auto;
}
