.comments-section {
  padding: 12px;
  display: flex;
  flex-direction: column;
  height: 100%;

  .comment-list {
    display: flex;
    flex-direction: column;
    flex: 6;
    position: relative;
    margin: 8px 0;
    overflow-y: auto;
  }

  .new-comment {
    padding: 8px;
  }
}
