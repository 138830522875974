@use 'src/styles/variables' as var;

.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  height: 100%;

  > .message {
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: var.$grey;

    > .code-404 {
      font-size: 48px;
      font-weight: bold;
    }

    > .info {
      font-size: 24px;
      font-weight: bold;
    }

    > .link {
    }
  }
}
