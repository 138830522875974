.sap-info-message {
  display: flex;
  flex-direction: row;
  padding: 8px 16px;
  gap: 8px;
  font-size: 12px;

  .title-message {
    font-weight: bold;
  }
}
