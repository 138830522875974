@use 'src/styles/variables' as var;
@use 'src/styles/mixin' as mixin;


button.notification-label {
  color: white;
  background-color: crimson;
  margin-left: 30px;
  cursor: default;

  &:hover {
    color: white;
    background-color: crimson;
  }
}

.notification-content {
  background-color: var.$light-yellow-2;
}

.notification-author {
  font-size: small;
  font-weight: bolder;
}

.notification-text {
}
