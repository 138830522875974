@use 'src/styles/variables' as var;

.title-permit-permit-package {
  padding: 30px 30px 30px 30px;
}

.card-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f1f4f9;

  .card-content-text {
    display: flex;
    flex-direction: row;
    font-size: 15px;
    margin: 0 2em 0;
    flex: 2;
  }

  .card-content-text > div {
    margin-left: 5px;
  }

  @media (max-width: 768px) {
    .card-content-text {
      flex-direction: column;
    }
    .card-content-text > div {
      margin-left: 0px;
    }
  }

  .export-button {
    display: flex;
    margin-left: 1em;
    color: #ffff;
    background-color: #0193d5;
  }
}
