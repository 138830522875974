@use 'src/styles/variables' as var;

@mixin radius-scrollbar($arc) {
  &::-webkit-scrollbar-thumb {
    -moz-border-top-right-radius: $arc;
    -webkit-border-top-right-radius: $arc;
    border-top-right-radius: $arc;

    -moz-border-bottom-right-radius: $arc;
    -webkit-border-bottom-right-radius: $arc;
    border-bottom-right-radius: $arc;
  }
  &::-webkit-scrollbar-track {
    -moz-border-top-right-radius: $arc;
    -webkit-border-top-right-radius: $arc;
    border-top-right-radius: $arc;

    -moz-border-bottom-right-radius: $arc;
    -webkit-border-bottom-right-radius: $arc;
    border-bottom-right-radius: $arc;
  }
}

@mixin submittedPermitDetailBackground($isCompleted) {
  @if $isCompleted {
    background-color: var.$submitted-permit-complete;
  } @else {
    background-color: var.$submitted-permit-in-process;
  }
}
