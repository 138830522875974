@use 'src/styles/variables' as var;

.dashboard {
  display: flex;
  flex-direction: column;

  .sticky-content {
    position: sticky;
    top: 0;
    z-index: 3;
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 25px;
    background-color: var.$white;

    > div {
      display: flex;
      flex-direction: column;
      gap: 16px;
      flex-grow: 1;
    }
    .title {
      display: flex;
      gap: 10px;
      font-size: 16px;

      .icon {
        color: #0098da;
      }
      .all-requests {
        font-weight: bold;
        text-transform: uppercase;
        color: var.$grey;
      }
    }

    .filter {
      display: flex;
      gap: 2%;
      flex-wrap: wrap;

      .icon {
        margin-top: 20px;
        color: #6f7072;
      }
      .MuiAutocomplete-root {
        width: 128px;
      }
      .MuiAutocomplete-root .MuiAutocomplete-inputRoot {
        padding-right: 0;
      }

      .results-export {
        display: flex;
        align-items: flex-end;
        gap: 16px;

        .result-count {
          color: var.$grey;
        }
        .export-button {
          padding: 0;
        }
      }
    }
  }

  .table {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 25px;

    .no-permits-message {
      margin-top: 100px;
      font-size: 28px;
      font-weight: lighter;
      color: #6f7072;
    }

    .loading {
      height: 168px;
      width: 168px;
      padding: 64px;
      margin: 16px;
      &.hidden {
        visibility: hidden;
      }
    }

    .MuiTableHead-root {
      .comment-cell {
        display: flex;
        color: #6f7072;
      }
    }

    .MuiTableBody-root {
      .MuiTableRow-root {
        vertical-align: baseline;
      }
      .row-group {
        vertical-align: middle;
      }
      .request-row {
        &:hover {
          background: #f0faff;
          cursor: pointer;
        }
        .comment-cell {
          color: #6f7072;
        }
      }
      .row-odd {
        background-color: #f7f9fc;
      }
      .row-border-none td {
        border-bottom: none;
      }
    }

    .MuiTableCell-root {
      overflow-wrap: anywhere;
    }

    .first-col {
      height: 0;
      width: 2%;
      max-width: 2%;

      > .group-line {
        height: 100%;
        padding: 16px 0;

        > hr {
          background: var.$lightest-grey;
          border-right-width: 2px;
        }
      }
    }
    .request-col {
      width: 6%;
      min-width: 85px;
    }
    .request-date-col {
      width: 8%;
      min-width: 100px;
    }
    .requestor-col {
      width: 12%;
      min-width: 105px;
    }
    .team-col {
      width: 16%;
      min-width: 110px;
    }
    .agency-col {
      width: 12%;
      min-width: 100px;
    }
    .job-number-col {
      width: 8%;
      min-width: 80px;
    }
    .job-title-col {
      width: 14%;
      min-width: 130px;
    }
    .last-updated-col {
      width: 8%;
      min-width: 100px;
    }
    .status-col {
      width: 16%;
      min-width: 200px;
    }
    .sub-status-col {
      width: 10%;
      min-width: 135px;
    }
    .comment-col {
      width: 2%;
    }
  }
}

.status-detail {
  display: flex;
  align-items: center;
  padding-top: 5px;
}
