@use 'src/styles/variables' as var;
@use 'src/styles/mixin' as mixin;

.permit-scope {
  width: 100%;
  height: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
  @include mixin.radius-scrollbar(10px);
  &.editable .MuiSvgIcon-root {
    color: var.$blue;
    margin-right: 10px;
  }
  > header {
    .MuiTypography-root {
      color: var.$submitted-permit-header-color;
    }
  }
  .permit-scope-info {
    > table {
      border-spacing: 16px;

      td {
        padding: 4px 16px;

        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 1;
        }
      }

      .table-cell {
        display: flex;
      }
    }
  }
  .permit-scope-trench {
    background-color: var.$white;
    overflow: visible;

    > .title {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px;
      border-radius: 5px;

      &:hover {
        background: #f7f9fc;
        cursor: pointer;
      }
    }
    .trench-specification-list-view {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 30px;
      gap: 20px;
    }
  }

  // .permit-scope-jucf {
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: flex-start;
  //   div:first-child {
  //     display: flex;
  //     align-items: center;
  //     margin-right: 10%;
  //     width: 10%;
  //   }
  //   div:last-child {
  //     display: flex;
  //     flex-direction: row;
  //     align-items: center;
  //     flex-wrap: wrap;
  //     justify-content: flex-start;
  //     gap: 20px;
  //     span {
  //       font-size: 10px;
  //     }
  //     .jucf {
  //       background-color: var.$white;
  //       padding: 4px 8px;
  //       border-radius: 5px;
  //       font-weight: 300;
  //     }
  //   }
  // }
}

.scope-dialog {
  .trench-specification-list-dialog {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .add-button {
    height: 32px;
    width: 32px;
    padding: 0;

    .MuiSvgIcon-root {
      height: 100%;
      width: 100%;
    }
  }
}
