.scope {
  .scope-form {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .digging {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: 24px;

      > .title {
        margin-top:25px;
        font-weight: normal;
        font-size: 20px;
      }

      > .options {
        margin-left: 16px;

        > .MuiFormControlLabel-root {
          width: fit-content;
        }

        > .MuiTextField-root {
          margin-bottom: 24px;
        }
      }

      .trench-specifications-collapse {
        user-select: none;

        > .title {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 8px;
          border-radius: 5px;

          &:hover {
            background: #f7f9fc;
            cursor: pointer;
          }
        }

        .items {
          display: flex;
          flex-direction: column;
          gap: 18px;
          margin-top: 8px;

          > .no-items-message {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 32px;
            border-radius: 5px;
            font-size: 20px;
            font-weight: bold;
            color: silver;
            background-color: white;
          }
        }

        .add-button {
          height: 32px;
          width: 32px;
          padding: 0;

          .MuiSvgIcon-root {
            height: 100%;
            width: 100%;
          }
        }
      }
    }

    // .jcuf-table {
    //   td:first-child {
    //     width: 30%;
    //   }
    //   .table-header {
    //     font-weight: bold;
    //   }
    //   .jucf-value {
    //     display: flex;
    //     gap: 12px;
    //     font-weight: bold;

    //     div {
    //       padding-top: 1px;
    //     }
    //   }
    //   .cip-value {
    //     display: flex;
    //     gap: 12px;

    //     div {
    //       background-color: white;
    //       padding: 4px 8px;
    //       border-radius: 5px;
    //       color: #000000;
    //     }
    //   }
    // }
  }

  .submit-button {
    margin-top: 16px;
  }
  .mt-25{
    margin-top: 25px;
  }
}
