@use 'src/styles/mixin' as mixin;
@use 'src/styles/variables' as var;

.permit-contacts {
  width: 100%;
  height: 100%;
  padding: 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
  @include mixin.radius-scrollbar(10px);
  > header {
    .MuiTypography-root {
      color: var.$submitted-permit-header-color;
    }
  }
  article {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .manager {
      display: flex;
      flex-direction: column;
      color: var.$grey;
      .MuiList-root {
        padding: 0;
        .MuiListItem-root {
          padding-left: 0;
        }
      }
    }
    .watchers {
      .MuiList-root {
        padding: 0;
        .MuiListItem-root {
          border-bottom: 1px solid var.$light-white;
          padding-left: 0;
          .MuiListItemText-root {
            color: var.$grey;
          }
          .MuiSvgIcon-root {
            color: var.$blue;
          }
        }
      }
    }
  }
}

.contacts-edit-dialog {
  .MuiDialogContent-root {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .requestor {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      div:first-child {
        display: flex;
        flex-direction: column;
        mark {
          background-color: var.$white;
          font-size: 10px;
          width: fit-content;
        }
      }
      &-graphic {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 80%;
        .divider {
          width: 50%;
          margin-left: 24px;
          border-bottom: 2px solid rgba(150, 21, 21, 0.12);
        }
        .info {
          display: flex;
          flex-direction: column;
          padding-left: 24px;
          border-left: 2px solid rgba(0, 0, 0, 0.12);
          color: var.$blue;
          &::before {
            border-top: 2px solid rgba(0, 0, 0, 0.12);
            content: '';
            display: block;
            margin-left: -24px;
            width: 10%;
          }
          &::after {
            border-top: 2px solid rgba(0, 0, 0, 0.12);
            content: '';
            display: block;
            margin-left: -24px;
            width: 10%;
          }
          .MuiIconButton-root {
            padding: 0;
          }
        }
      }
    }
    .watchers {
      position: relative;
      display: flex;
      flex-direction: column;
      background-color: white;
      width: 100%;
      border-radius: 5px;
      gap: 16px;
      margin-top: 8px;

      > .title {
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 500;
      }

      .watcher {
        td {
          padding: 8px 16px;

          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
        }

        .title-cell,
        .main-contact-cell {
          > .title {
            margin-top: -12px;
            color: rgba(0, 0, 0, 0.6);
          }
        }

        .main-contact-cell {
          text-align: center;
          > .value {
            > .MuiFormControlLabel-root {
              margin-left: 0;
              margin-right: 0;
            }
          }
        }

        .MuiOutlinedInput-root {
          .MuiIconButton-root {
            padding: 4px;

            .MuiSvgIcon-root {
              height: 18px;
              width: 18px;
            }
          }
        }
      }

      .add-button {
        height: 32px;
        width: 32px;
        padding: 0;

        .MuiSvgIcon-root {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}
