.reassignment-dialog {
  .dialog-content {
    display: flex;
    flex-direction: column;
    width: 360px;
    gap: 28px;
    padding: 16px 0;

    > .MuiAutocomplete-root {
      width: 280px;
    }
  }
}
