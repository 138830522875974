@use 'src/styles/variables' as var;

main {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 93vh;

  .submitted-permit-header-content {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
  }

  .sap-status {
    background-color: lightgray;
    color: black;
    display: inline-flex;
    border-radius: 5px;
    max-width: 260px;
    justify-content: center;
    min-width: 50px;
    padding: 6px 24px;
    text-align: center;
  }
  .sap-status > p {
    margin: 0.5em 0.2em;
  }

  .sap-status-in {
    background-color: #cff4fc;
    color: #055160;
    display: inline-flex;
    border-radius: 5px;
    max-width: 260px;
    justify-content: center;
    min-width: 50px;
    padding: 6px 24px;
    text-align: center;
  }
  .sap-status-in > p {
    margin: 0.5em 0.2em;
  }

  .sap-status-ip {
    background-color: #e2e3e5;
    color: #2b2f32;
    display: inline-flex;
    border-radius: 5px;
    max-width: 260px;
    justify-content: center;
    min-width: 50px;
    padding: 6px 24px;
    text-align: center;
  }
  .sap-status-ip > p {
    margin: 0.5em 0.2em;
  }

  .sap-status-pc {
    background-color: #fff3cd;
    color: #664d03;
    display: inline-flex;
    border-radius: 5px;
    max-width: 260px;
    justify-content: center;
    min-width: 50px;
    padding: 6px 24px;
    text-align: center;
  }
  .sap-status-pc > p {
    margin: 0.5em 0.2em;
  }

  .sap-status-co {
    background-color: #ffd9b8;
    color: #712202;
    display: inline-flex;
    border-radius: 5px;
    max-width: 260px;
    justify-content: center;
    min-width: 50px;
    padding: 6px 24px;
    text-align: center;
  }
  .sap-status-co > p {
    margin: 0.5em 0.2em;
  }

  .sap-status-re {
    background-color: #d1e7dd;
    color: #0a3622;
    display: inline-flex;
    border-radius: 5px;
    max-width: 260px;
    justify-content: center;
    min-width: 50px;
    padding: 6px 24px;
    text-align: center;
  }
  .sap-status-re > p {
    margin: 0.5em 0.2em;
  }

  > .body {
    display: flex;
    flex-direction: row;
    flex: 1;
    height: 100%;
    .submitted-permit {
      width: 70%;
      overflow-x: hidden;
      > header {
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 0;
        padding: 20px 0px;
        margin: 0px 40px 0px 40px;
        border-bottom: 1px solid var.$light-grey;
        background-color: var.$white;
        z-index: 2;
        .header-title {
          display: flex;
          align-items: center;
          .MuiTypography-root {
            width: 25vw;
            font-weight: bold;
          }
          .request-number {
            background-color: var.$submitted-permit-header-color;
            color: white;
            border-radius: 5px;
            padding: 10px;
            margin-right: 20px;
            min-width: 10%;
            text-align: center;
            max-width: 300px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
        .header-info {
          display: flex;
          align-items: center;
          .MuiTypography-root {
            margin-right: 20px;
            color: #61ad55;
            width: 200px;
            text-align: right;
          }
          .job-number {
            max-width: 300px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            padding: 10px;
            background-color: white;
            border-radius: 5px;
            text-align: center;
          }
        }
      }
      > .content {
        padding: 40px;
      }
    }
    .comments {
      background-color: white;
      width: 30%;
    }
  }
}

@media (max-width: 1300px) {
  main {
    > .body {
      .submitted-permit {
        header {
          .header-title {
            .MuiTypography-root {
              width: auto;
            }
          }
        }
      }
    }
  }
}
