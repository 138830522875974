$dark-blue: #001C71;
$blue: #0193D5;
$white: #f1f4f9;
$white-blue: #daebff;
$light-white: #dadada;
$light-yellow: #e1f2e2;
$lightest-grey: #e3e7ee;
$light-grey: #c4c4c4;
$grey: #4b4b4b;
$light-yellow-2: rgb(251, 227, 164);
$red:#CD3729;

$submitted-permit-header-color: $dark-blue;
$submitted-permit-complete: $light-yellow;
$submitted-permit-in-process: $white-blue;
