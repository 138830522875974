@use 'src/styles/variables' as var;

.comment-textbox {
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  padding-right: 0;

  > .mention-textbox-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    flex: 8;

    > .mention-textbox {
      height: 100%;

      .mention-textbox__control {
        .mention-textbox__highlighter {
          padding: 8px 12px;
          border: 2px solid transparent !important;
          border-radius: 3px;
          word-break: break-word;
        }

        .mention-textbox__input {
          padding: 8px 12px;
          border: 2px solid var.$light-white;
          border-radius: 3px;

          &:focus-visible {
            outline: none;
            border-color: var.$blue;
          }
        }
      }

      .mention-textbox__suggestions__list {
        position: absolute;
        width: max-content;
        background-color: white;
        bottom: 14px;
        max-height: 320px;
        overflow: auto;
        background-color: 'white';
        border: 1px solid rgba(0, 0, 0, 0.15);
        box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
          0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
      }

      .mention-item {
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 4px 8px;

        &.focused {
          background-color: var.$white;
        }
      }
    }

    > .text-counter {
      position: absolute;
      bottom: 0;
      right: 6px;
      color: var.$light-grey;
      
      &.error {
        color:var.$red;
      }
    }
  }

  > .actions {
    display: flex;
    justify-content: flex-end;
    flex: 1;

    > .cancel {
      display: none;
    }
  }

  &.editbox {
    flex-direction: column;
    align-items: flex-end;

    > .actions {
      display: flex;
      justify-content: flex-end;

      > .cancel {
        display: flex;
      }
    }
  }
}
