@use 'src/styles/variables' as var;

.root-status-timeline > li:before,
.child-status-timeline > li:before {
  display: none;
}
.child-status-timeline {
  margin-top: 8px;
}
.MuiTimelineDot-filled {
  padding: 0 !important;
}
li.MuiTimelineItem-root {
  min-height: 40px !important;

  div.MuiTypography-root {
    padding-top: 0 !important;
  }

  .MuiChip-root {
    margin-top: -4px;
  }
}
.MuiTimelineSeparator-root .MuiTimelineDot-root {
  margin: 4px 0;
}

.statusName {
  font-weight: bold;
}
