.permit-card {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px;
  border-radius: 5px;
  min-height: 160px;
  min-width: 256px;
  width: 350px;
  gap: 8px;

  &:hover {
    background: #f0faff;
    cursor: pointer;
  }

  .header {
    display: flex;
    justify-content: space-between;

    .MuiChip-root {
      height: 2em;
    }
  }

  .team {
    display: flex;
  }

  .coordinator-avatar {
    margin: auto !important;
    width: 2.65em;
  }

  .card-job-title {
    margin: 0;
    line-height: 1em;
  }

  .card-agency-name {
    margin: 0;
  }
}
