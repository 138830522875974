@use 'src/styles/variables' as var;
@use 'src/styles/mixin' as mixin;

.permit-location {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding: 30px;
  overflow: auto;
  @include mixin.radius-scrollbar(10px);

  &.editable {
    cursor: pointer;
  }

  .MuiTypography-root {
    color: var.$submitted-permit-header-color;
  }

  .permit-location-data {
    padding-right: 8px;
    font-size: 14px;

    .address-list {
      display: flex;
      flex-direction: column;
      gap: 4px;

      > .list-title {
        margin-top: 8px;
      }

      > .list-item {
        font-weight: bold;
        display: flex;
        flex-direction: row;
        gap: 4px;
      }
    }
  }
  .permit-location-map {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}

.location-btn-box{
  padding:0 10px 0 0;
}

.location-dialog {
  .MuiDialog-container {
    .location-header {
      position: absolute;
      left: 16px;
      z-index: 3;
      padding: 4px;
      backdrop-filter: blur(3px);
      color: var.$submitted-permit-header-color;
    }
    .MuiDialogContent-root {
      min-height: 60vh;
      padding: 0;

      .location-dialog-content-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .addresses-form {
          display: flex;
          flex-direction: column;
          gap: 32px;
          padding: 32px 24px;

          .primary-address {
            display: flex;
            flex-direction: column;
            gap: 24px;

            .icon-primary-address,
            .icon-lat-long,
            .icon-thomas-bros {
              color: #cb4539;
            }
          }

          .supporting-addresses {
            width: 100%;
            display: flex;
            flex-direction: column;
            background-color: white;
            padding: 16px;
            border-radius: 5px;
            gap: 24px;
            background-color: var.$white;
            max-height: 100%;
            overflow: auto;

            .icon-supporting-addresses {
              color: #001aff8a;
            }

            > .title {
              text-transform: uppercase;
              font-weight: bold;
            }

            > .address {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              gap: 16px;

              > div {
                display: flex;
                flex: 1;
                flex-direction: column;
                gap: 4px;

                .supporting-addr-latlng {
                  display: flex;
                  align-items: center;
                  gap: 4px;
                }
              }
            }

            .add-button {
              height: 32px;
              width: 32px;
              padding: 0;

              .MuiSvgIcon-root {
                height: 100%;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
