@use 'src/styles/variables' as var;

.title-and-save-status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 -32px;
    margin-top: -16px;
    padding: 16px 32px;
    background-color: var.$lightest-grey;
  
    > h2 {
    margin-bottom: 0;
    }
  
    .saving-status-icon {
      display: flex;
      align-items: center;
      font-size: small;
      gap: 10px; 
     
      > div { 
        display: flex; 
        gap: 6px; 
      }
    }
  }
  