body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
      background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
      background: #888;
  }
  
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
      background: #555;
  } 
  
  scrollbar-width: thin;
  scrollbar-color: #f1f1f1 #888 !important;
    
}

hr{
  margin-bottom:20px;
}