@use 'src/styles/variables' as var;

.contacts-form {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .requestor {
    display: flex;
    align-items: center;
    padding: 16px 0;

    .label {
      font-size: 11px;
    }
    .divider {
      width: 200px;
      margin-left: 24px;
      border-bottom: 2px solid rgba(0, 0, 0, 0.12);
    }

    .info {
      display: flex;
      flex-direction: column;
      padding-left: 24px;
      border-left: 2px solid rgba(0, 0, 0, 0.12);
      color: var.$blue;

      div {
        margin: 4px 0;
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }

      &::before {
        border-top: 2px solid rgba(0, 0, 0, 0.12);
        content: '';
        display: block;
        margin-left: -24px;
        width: 10%;
      }

      &::after {
        border-top: 2px solid rgba(0, 0, 0, 0.12);
        content: '';
        display: block;
        margin-left: -24px;
        width: 10%;
      }

      .MuiIconButton-root {
        padding: 0;

        .MuiSvgIcon-root {
          height: 18px;
          width: 18px;
        }
      }
    }
  }

  .watchers {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 16px;
    min-width: 488px;
    width: fit-content;
    border-radius: 5px;
    gap: 16px;
    margin-top: 8px;

    > .title {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
    }

    .watcher {
      td {
        padding: 8px 16px;

        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }

      .title-cell,
      .main-contact-cell {
        > .title {
          margin-top: -12px;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.6);
        }
      }

      .main-contact-cell {
        > .value {
          display: flex;
          justify-content: center;

          > .MuiFormControlLabel-root {
            margin-left: 0;
            margin-right: 0;
          }
        }
      }

      .MuiOutlinedInput-root {
        .MuiIconButton-root {
          padding: 4px;

          .MuiSvgIcon-root {
            height: 18px;
            width: 18px;
          }
        }
      }
    }

    .add-button {
      height: 32px;
      width: 32px;
      padding: 0;

      .MuiSvgIcon-root {
        height: 100%;
        width: 100%;
      }
    }
  }

  .submit-button {
    margin-top: 16px;
  }

  .MuiFormControl-root {
    width: 256px;
  }
}

.contacts-tooltip-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  > .tooltip-text {
    font-weight: 600;
  }

  > div {
    > .MuiIconButton-root {
      padding: 4px;

      .MuiSvgIcon-root {
        color: #0098da;
        height: 16px;
        width: 16px;
      }
    }
  }
}
