.schedule-form {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .description {
    font-size: 20px;
  }

  .MuiFormControl-root.MuiTextField-root {

    .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.MuiFormLabel-root {
      line-height: 14px;
      height: 40px;
    }

    .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
      height: 40px;
    }
  }

  .time-range {
    display: inline-flex;

    .start-time .schedule-form .time-range span.start-time input, .schedule-form .time-range span.end-time input {
      height: 40px;
      margin-top: -5px;
    }

    span.start-time, span.end-time {
      padding: 5px;

      input {
        background-color: rgba(0, 0, 0, 0);
        padding: 5px; 
      }
    }

    .ant-picker-focused {
      box-shadow: unset;
      border: solid 2px #0098da;
    }

    .ant-picker {
      width: 170px;
      margin-right: 10px;
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0);
      &:focus{
      border: solid 1px rgba(0, 0, 0, 0.7);
      box-shadow: unset;
      box-shadow: 0 0 0 0;
      }

      .ant-picker-input {
        color: #0098da;

        input {
          height: 30px;
        }

        input::placeholder {
          color: rgba(0, 0, 0, 0.7);
        }

        .ant-picker-clear {
          margin-right: 1.6em;
        }

        .ant-picker-suffix {

          .anticon-clock-circle {
            color: #0098da;
            font-size:20px;
          }
        }
      }

      .ant-picker:hover {
        box-shadow: 0 0 0 0;
        border: solid 2px rgba(0, 0, 0, 0.7);
      }

      .ant-picker-focused {
        box-shadow: 0 0 0 0;
        border: solid 2px #0098da;
      }
    }  
  }

  .dates-inline-container {
    display: inline-flex;

    .dates-container {
      display: flexbox;
    }

    .card-notification-container {
      display: block;

      .card-notification {
        background-color: bisque;
        min-height: 3em;
        margin: 0 1em 1em 1em;

        div {
          padding: 0.5em;
          height: auto;
        }
      }

      .MuiTypography-root {
        font-weight: 600;
      }
    }

    .cmp-due-date {
      margin-top: 2em;
    }
  }

  .submit-button {
    margin-top: 16px;
  }

  .MuiFormControl-root {
    width: 300px;
  }
  
}
