@use 'src/styles/variables' as var;

.permit-attachments-tile {
  padding: 30px;
}

table.attachments {
  border: 0 !important;
}

.submitted-attachments-dialog {
  .MuiDialogContent-root {
    padding: 20px 32px;
  }
}
