.location-form {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .location-title {
    margin-top: -44px;
    height: 0em;
    font-weight: bold;
    line-height: 7;
    padding-left: 1em;
    z-index: 1;
  }

  .map-container {
    position: relative;
    margin-left: -32px;
    margin-right: -32px;
  }

  .addresses-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 16px;
    gap: 64px;

    .icon-primary-address,
    .icon-lat-long,
    .icon-thomas-bros {
      color: #cb4539;
    }
    .icon-supporting-addresses {
      color: #001aff8a;
    }

    .primary-address {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 256px;
    }

    .supporting-addresses {
      display: flex;
      flex-direction: column;
      background-color: white;
      padding: 16px;
      min-width: 388px;
      width: fit-content;
      border-radius: 5px;
      gap: 24px;

      > .title {
        text-transform: uppercase;
        font-weight: bold;
      }

      > .address {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 16px;

        > div {
          display: flex;
          flex: 1;
          flex-direction: column;
          gap: 4px;

          .supporting-addr-latlng {
            display: flex;
            align-items: center;
            gap: 4px;
          }
        }
      }

      .add-button {
        height: 32px;
        width: 32px;
        padding: 0;

        .MuiSvgIcon-root {
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  .submit-button {
    margin-top: 16px;
  }
}
