@use 'src/styles/variables' as var;

.app-nav {
  @-moz-document url-prefix() {
    .content .content-container {
      overflow: hidden;
    }
  }

  > .content {
    background-color: var.$white;
    height: 100%;
    min-height: 100vh;
    display: flex;

    .content-container {
      height: 100%;
      min-height: 100vh;
      width: 100%;
      background-color: var.$white;
    }
  }

  .sidebar-nav {
    .MuiPaper-root {
      border-right: 1px solid rgba(255, 255, 255, 0.2);
    }
  }

  .logo-text {
    font-weight: 600;
    padding-left: 1em;
    font-size: 18px;
  }

  .logo-container {
    height: 25px;
    padding-left: 0.5em;
  }

  .avatar-row {
    background-color: #0c75df;
    padding: 1em;
    align-items: center;
    .MuiAvatar-circular {
      background-color: #aadd6d;
    }
  }

  .empty-space {
    margin: auto;
  }

  .MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft {
    background-color: #001c71;
    color: white;

    .sidebar-label {
      text-align: left;
      padding-left: 1.5em;
      padding-top: 1.5em;
      opacity: 0.4;
      font-size: 12px;
    }

    .nav-button {
      padding: 1em 1.5em;
      .css-tlelie-MuiListItemText-root {
        margin: 0;
      }
    }

    .nav-list {
      padding: 0;
    }

    .MuiListItemIcon-root,
    .MuiSvgIcon-root {
      color: white;
    }

    .MuiIconButton-sizeMedium {
      padding: 0;
    }

    button .MuiSvgIcon-fontSizeMedium {
      font-size: 32px;
      opacity: 0.4;
    }

    .nav-header {
      padding: 0;
    }

    .css-cveggr-MuiListItemIcon-root {
      min-width: 40px;
    }
  }
}
