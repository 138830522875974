.attachment-item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > .info-grid {
    align-items: center;

    > .MuiGrid-item {
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
