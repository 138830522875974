@use 'src/styles/variables' as var;

.drag-drop-input {
  border: 2px dashed #c4c4c4;
  border-radius: 5px;
  height: 200px;
  cursor: pointer;

  > .title {
    background-color: #f1f4f9;
    font-size: 11px;
    position: relative;
    text-align: center;
    width: fit-content;
    margin-top: -9px;
    margin-left: 8px;
    padding: 0 4px;
  }

  > .desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 16px;
  }
}
