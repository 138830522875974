@use 'src/styles/variables' as var;

.new-permit {
  padding: 32px;

  .new-permit-form {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 32px;
    padding: 32px 40px;
    background-color: white;
    width: fit-content;
    border-radius: 5px;

    // radio buttons
    .job-type {
      display: flex;
      gap: 8px;

      .MuiFormGroup-root {
        gap: 48px;
      }
    }

    // input + checkbox
    .job-number {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .controls {
        display: flex;
        align-items: center;
        gap: 48px;

        .input {
          display: flex;
          border-radius: 3px;
          width: 330px;

          .MuiInputBase-input {
            padding: 6.5px 14px;
          }
          .MuiInputBase-root {
            margin: 1px;
          }
          .Mui-focused {
            margin: 0px;
          }
        }
        .input-disabled:hover {
          border-color: var.$light-grey;
        }
      }
      .title-message {
        color: red;
      }
    }
  }

  .results {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    .grouped {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    .permits {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }
    .no-results {
      font-size: 18px;
    }

    .MuiButton-root {
      .MuiButton-endIcon {
        margin-left: 16px;

        .MuiSvgIcon-root {
          transform: rotate(90deg);
        }
      }
    }
  }
}
