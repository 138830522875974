.permit-form {
  display: flex;
  .form-nav {
    color: white;
  }

  .permit-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    width: stretch;
    overflow: auto;
    height: 100vh;

    > .sap-offline-message {
      position: absolute;
      z-index: 4;
      width: 60%;
      top: 16px;
      left: 32px;
    }

    > .body {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex: 1;
      overflow: hidden;

      > .form {
        width: 70%;
        padding: 16px 32px;
        overflow: auto;
      }

      .comments {
        background-color: white;
        width: 30%;
      }
    }
  }
}
