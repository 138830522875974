.cell {
  display: flex;
  flex-direction: column;
}

.dimensions {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
