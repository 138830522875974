.details-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: fit-content;
  margin-top: 32px;

  .budget-code {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;

    .MuiSvgIcon-root {
      color: #6f7072;
    }
  }

  .agencies {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 16px;
    min-width: 344px;
    width: fit-content;
    border-radius: 5px;
    gap: 16px;

    .title {
      text-transform: uppercase;
      font-weight: bold;
    }

    .agency {
      display: flex;
      flex-direction: row;
      gap: 16px;
    }

    .add-button {
      height: 32px;
      width: 32px;
      padding: 0;

      .MuiSvgIcon-root {
        height: 100%;
        width: 100%;
      }
    }
  }

  .submit-button {
    margin-top: 16px;
  }

  .MuiFormControl-root {
    width: 256px;
  }
}
