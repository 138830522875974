.attachments-section {
  .attachments-content {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .attachment-list {
      display: flex;
      flex-direction: column;
      gap: 6px;

      > .request-form-attachment-item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px;
        background-color: white;
      }
    }

    .loading-circle {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.mr-15px{
  margin-right:15px;
}