@use 'src/styles/variables' as var;

.permit-header {
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 12px 24px;
  z-index: 3;

  .badges {
    margin-left: auto;
  }

  .clear-button {
    visibility: hidden;
  }

  .Mui-focused + .clear-button {
    visibility: visible;
  }

  &:hover {
    .clear-button {
      visibility: visible;
    }
  }
}

// needs action popper
#needs-action-popper {
  z-index: 3;
  width: 280px;
  height: auto;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  position: fixed;

  .MuiBox-root {
    border-color: #ccc;
  }

  .needs-action-title {
    font-weight: 600;
    color: #cb4539;
    line-height: 1em;
  }
  .needs-action-job-details {
    font-weight: 900;
    cursor: pointer;
    padding-top: 10px;
  }
  .needs-action-text {
    cursor: pointer;
    font-weight: 600;
    margin: 0.4em 0;
    height: 40px;
    color: var.$blue;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

//Unread Comments Popper
#unread-comments-popper {
  z-index: 3;
  width: 280px;
  height: auto;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  position: fixed;

  .MuiBox-root {
    border-color: #ccc;
  }

  .new-comment-item {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    gap: 8px;
    margin-top: 10px;

    > .author-avatar {
      margin-top: 8px;
    }

    > div {
      > .unread-comments-title {
        font-weight: 600;
        line-height: 1em;
      }
      > .unread-comment-details {
        font-weight: 900;
      }
      > .author-details {
        color: dimgray;
      }
    }
  }

  .needs-action-text {
    cursor: pointer;
    font-weight: 600;
    margin: 0.4em 0;
    height: 40px;
    color: var.$blue;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

#status-select {
  margin-top: 4px;
  
  & > .MuiInput-root:before {
    border: 0 !important;
  }
}

.rotate {
  transform: rotate(-180deg);
}